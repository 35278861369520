.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiCard-root.css-106fcdi-MuiPaper-root-MuiCard-root {
    cursor: pointer !important;
}

ul.MuiList-root.MuiList-subheader.css-ux4tyi-MuiList-root {
    overflow-y: scroll;
    height: 500px;
}

ul.MuiList-root.MuiList-subheader.css-ux4tyi-MuiList-root::-webkit-scrollbar {
    width: 0px;
}

ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root.css-enswfn-MuiList-root-MuiMultiSectionDigitalClockSection-root {
    scrollbar-width: none !important; /* For Firefox */
    -ms-overflow-style: none !important; /* For Internet Explorer & Edge */
}

ul.MuiList-root.MuiList-padding.MuiMultiSectionDigitalClockSection-root.css-enswfn-MuiList-root-MuiMultiSectionDigitalClockSection-root::-webkit-scrollbar {
    display: none !important; /* For Chrome, Safari, and Opera */
}

ul.MuiList-root.MuiList-subheader.css-ux4tyi-MuiList-root::-webkit-scrollbar-thumb {
    opacity: 0 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiCard-root.css-1lfjwor-MuiPaper-root-MuiCard-root{
    padding: 1rem !important;
}
